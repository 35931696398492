import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import classNames from "classnames"

import Layout from "../components/layout"
import Hero from "../components/hero"
import Section from "../components/section"
import Columns from "../components/columns"
import SEO from "../components/seo"
import Headline from "../components/headline"
import P from "../components/paragraph"
import LanguageSwitch from "../components/languageSwitch"
import Comment from "../components/comment"

import Logo from "../images/sumway.svg"
import Evidensia from "../images/evidensia.svg"

import styles from "./index.module.css"

export const query = graphql`
  query {
    bg: file(relativePath: { eq: "bg.jpg" }) {
      childImageSharp {
        fluid(quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    memira: file(relativePath: { eq: "memira.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    svalorna: file(relativePath: { eq: "svalorna.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    anders: file(relativePath: { eq: "anders-granstrom.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO lang="sv" />
      <Hero
        bg={
          <Img
            fluid={{ ...data.bg.childImageSharp.fluid, sizes: "100vw" }}
            objectFit="cover"
            className={styles.heroImage}
            style={{ position: "absolute" }}
          />
        }
      >
        <div className={styles.heroInner}>
          <Headline tagName="h1" className={styles.heroHeadline}>
            <Logo
              style={{ width: "70%", maxWidth: "80rem", margin: "auto" }}
              title="Sumway"
            />
          </Headline>
        </div>
        <LanguageSwitch />
      </Hero>
      <Section
        title={
          <>
            Hej där{" "}
            <span role="img" aria-label="waving" className={styles.wavingHand}>
              👋
            </span>
          </>
        }
        textAlign="center"
        bgColor="grey"
        className={styles.wavingHandParent}
      >
        <P style={{ padding: "0 1em" }}>
          Om du är intresserad av blixtsnabba hemsidor, har du kommit rätt.
        </P>
      </Section>
      <Section
        title="Det är inte komplicerat"
        textAlign="center"
        className={styles.balloonParent}
      >
        <Columns>
          <>
            <Headline>
              Användare{" "}
              <span className={styles.inlineBlock}>väntar inte på dig</span>
            </Headline>
            <P>
              En väntetid längre än 3 sekunder får dig att tappa ~30% av dina
              besökare.
            </P>
          </>
          <>
            <Headline>
              Designa för{" "}
              <span className={styles.inlineBlock}>mobila enheter</span>
            </Headline>
            <P>
              Mer än 50% av dina besökare har mobiltelefon &ndash; och de blir
              bara fler och fler varje dag. Se till att anpassa för det.
            </P>
          </>
          <>
            <Headline>
              Avancerat är inte{" "}
              <span className={styles.inlineBlock}>lika med komplicerat</span>
            </Headline>
            <P>
              <>
                Använd inte komplicerad design på din hemsida. Gör det istället
                enkelt{" "}
                <span className={classNames([styles.inlineBlock])}>
                  (och roligt{" "}
                  <span
                    role="img"
                    aria-label="balloon"
                    className={styles.balloon}
                  >
                    🎈
                  </span>
                  )
                </span>{" "}
                för dina besökare.
              </>
            </P>
          </>
        </Columns>
      </Section>
      <Section title="Vår erfarenhet" bgColor="grey" textAlign="center">
        <Columns>
          <>
            <Headline>WordPress</Headline>
            <P>Över 10 års erfarenhet av att bygga specialanpassade teman.</P>
          </>
          <>
            <Headline>JavaScript &amp; PHP</Headline>
            <P>Djup kunskap inom komponentbaserad utveckling.</P>
          </>
          <>
            <Headline>CSS</Headline>
            <P>
              Använder senaste CSS-funktionerna, men ser även till att stödja
              gamla webbläsare.
            </P>
          </>
          <>
            <Headline>React</Headline>
            <P>
              Lång erfarenhet av att dela upp komponenter i återanvändbara
              delar.
            </P>
          </>
          <>
            <Headline>Git</Headline>
            <P>
              Använder alltid versionshantering &amp; feature branches när det
              behövs.
            </P>
          </>
          <>
            <Headline>Laravel &amp; Lumen</Headline>
            <P>
              För att bygga API:er eller webbplatser med väldigt specific
              funktionalitet.
            </P>
          </>
        </Columns>
      </Section>
      <Section title="Fantastiska kunder" textAlign="center">
        <Columns gutters="large" vAlign="center">
          <a
            href="https://svalorna.org"
            target="_blank"
            rel="noopener noreferrer"
            style={{ opacity: ".8" }}
          >
            <Img
              fluid={data.svalorna.childImageSharp.fluid}
              className={styles.clientImage}
              alt="Svalorna Indien Bangladesh"
            />
          </a>
          <a
            href="https://www.memira.se"
            target="_blank"
            rel="noopener noreferrer"
            style={{ opacity: ".7" }}
          >
            <Img
              fluid={data.memira.childImageSharp.fluid}
              className={styles.clientImage}
              alt="Memira Eyecenter"
            />
          </a>
          <a
            href="https://evidensia.se"
            target="_blank"
            rel="noopener noreferrer"
            style={{ opacity: ".7" }}
          >
            <Evidensia className={styles.clientImage} title="Evidensia" />
          </a>
        </Columns>
      </Section>

      <Section textAlign="center" bgColor="grey">
        <Comment image={data.anders} />
      </Section>



      <Section title="Kontakta oss" textAlign="center" bgColor="white">
        <Columns>
          <>
            <Headline>E-post</Headline>
            <P>
              <a href="mailto:hello@sumway.se">hej@sumway.se</a>
            </P>
          </>
          <>
            <Headline>Telefon</Headline>
            <P>
              <a href="tel:+46738046446">+46 (0) 73-804 64 46</a>
            </P>
          </>
          <>
            <Headline>LinkedIn</Headline>
            <P>
              <a
                href="https://bit.ly/2mbJYVi"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://bit.ly/2mbJYVi
              </a>
            </P>
          </>
        </Columns>
      </Section>
    </Layout >
  )
}

export default IndexPage
