import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import P from "../components/paragraph"

import { commentContainer, comment, commentImage } from "./comment.module.css"

const Comment = ({ image }) => {
    return (
        <blockquote className={commentContainer}>
            <Img
                fluid={image.childImageSharp.fluid}
                className={commentImage}
                alt=""
            />
            <footer className={comment}>
                <P>“Jag har jobbat återkommande med Sumway och är väldigt nöjd med vad de levererat t.ex. <a href="https://hockeyresultat.se/shl-tabell/">tabeller för SHL</a>. Kvalitet i fokus, tar hänsyn till budget med bra återkoppling. Kan varmt rekommendera att ni anlitar dem.”</P>
                <P> Anders Granström, Sportnyhet AB</P>
            </footer>
        </blockquote>
    )
}
export default Comment